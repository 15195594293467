import { Box, Divider } from '@mui/material'
import Container from '@mui/material/Container'
import { useState } from 'react'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiButton from 'components/button'
import { workFlowApi } from 'hooks/api/workflow'

export default function WorkFlowPage() {
  const [orderSccessMsg, setOrderSccessMsg] = useState<string>('')
  const [securitySccessMsg, setSecuritySccessMsg] = useState<string>('')
  const [assignSccessMsg, setAssignSccessMsg] = useState<string>('')
  const links = [
    {
      title: 'ワークフロー',
    },
  ]

  const cleanup = () => {
    if (confirm('実行しますか')) {
      workFlowApi()
        .securityReviewCleanup()
        .then((res) => {
          setOrderSccessMsg(res.data.orderMsg)
          setSecuritySccessMsg(res.data.securityMsg)
          setAssignSccessMsg(res.data.assignMsg)
        })
    }
  }

  const contractBiingCleanup = () => {
    if (confirm('実行しますか')) {
      workFlowApi()
        .contractBillingCleanup()
        .then((res) => {
          console.log(res.data)
        })
    }
  }

  return (
    <Container>
      <Box>
        <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
        <Divider>配置データのクリーンアップ</Divider>
        <KanseiButton onClick={cleanup}>実行</KanseiButton>
        <p>受注の住所更新数:{orderSccessMsg}</p>
        <p>警備員の住所更新数:{securitySccessMsg}</p>
        <p>配置の距離更新数:{assignSccessMsg}</p>
      </Box>
      <Box>
        <Divider>単価の曜日系のクリーンアップ</Divider>
        <KanseiButton onClick={contractBiingCleanup}>実行</KanseiButton>
      </Box>
    </Container>
  )
}
