import { AxiosResponse } from 'axios'

import { _axios } from './axios'

const GEN_ULID = (count: number) => `/tools/gen-ulid?count=${count}`

export const toolApi = () => {
  const genUlid = async (count: number): Promise<AxiosResponse<string[]>> => {
    return await _axios().get(GEN_ULID(count))
  }

  return {
    genUlid,
  }
}
