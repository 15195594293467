import { ThemeProvider } from '@mui/material'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { baseRoutes } from 'routes/base'
import { workFlowRoutes } from 'routes/workflow'

import { GetAuthSession } from './hooks/api/axios'
import { companyRoutes } from './routes/company'
import { toolRoutes } from './routes/tool'

import Layout from './App'
import NotFoundPage from './notFound'
import { defaultTheme } from './valiables'

const root = createRoot(document.getElementById('root') as HTMLElement)

const isAuthenticated = (): boolean => {
  const sessionKey = GetAuthSession()
  if (!!sessionKey) {
    return true
  } else {
    return false
  }
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <Routes>
          {baseRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Layout />
              ) : (
                <Navigate to={'/login'}></Navigate>
              )
            }
          >
            {companyRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            {workFlowRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            {toolRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
