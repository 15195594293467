import { Box, Divider } from '@mui/material'
import Container from '@mui/material/Container'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiTextField from 'components/textField'

import { toolApi } from '../../hooks/api/tool'

const GenULID = () => {
  const [result, setResult] = useState<string[]>([])
  type Form = {
    count: string
  }

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {},
  })

  const onSubmit = async (form: Form) => {
    if (confirm('生成しますか？')) {
      const count = parseInt(form.count)
      const ulids = await toolApi().genUlid(count)
      setResult(ulids.data)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <KanseiTextField id={'count'} control={control} />
      <textarea
        style={{ width: '100%', height: '200px' }}
        value={result && result.join('\n')}
        readOnly
      />
    </Box>
  )
}

export default function ToolPage() {
  const links = [
    {
      title: '開発用ツール',
    },
  ]
  return (
    <Container>
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <Divider>ULID生成</Divider>
      <GenULID />
    </Container>
  )
}
